import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { useContentType } from '@confluence/page-context';
import { getLogger } from '@confluence/logger';
import { fg } from '@confluence/feature-gating';
import { SPAViewContext } from '@confluence/spa-view-context';
import { sanitizeDataForSSR, useRenderServerPlaceholder } from '@confluence/ssr-utilities';
import { waitForGlobal } from '@confluence/wrm';

import { LegacyBridgeQuery } from './LegacyBridgeQuery.graphql';
import { OptimizedLegacyBridgeQuery } from './OptimizedLegacyBridgeQuery.graphql';
import type { OptimizedLegacyBridgeQuery as OptimizedLegacyBridgeQueryType } from './__types__/OptimizedLegacyBridgeQuery';

const logger = getLogger('LegacyBridgeNext');
export function LegacyBridgeNextInner({ contentId }) {
	const [contentType] = useContentType();

	const { experimentFeatures } = useContext(SPAViewContext);
	const nonceValue = window.__SSR_NONCE_VALUE__;
	const renderServerPlaceholder = useRenderServerPlaceholder();
	const queryToUse = fg('confluence_fe_call_new_legacy_bridge_query')
		? OptimizedLegacyBridgeQuery
		: LegacyBridgeQuery;

	const { data, error } = useQuery(queryToUse, {
		variables: {
			contentId: contentId || '',
			isBlog: contentType === 'blogpost',
		},
		context: { single: true },
	});

	const { ptpage, content } = data || {};
	const contentNode = content?.nodes?.[0];
	const spaceNode = contentNode?.space;
	const pageVersion = contentNode?.version?.number;

	const ajsMetaParameters: { [key: string]: any }[] = [
		{ key: 'space-key', value: spaceNode?.key },
		{ key: 'space-name', value: spaceNode?.name },
		{ key: 'space-type', value: spaceNode?.type },

		{ key: 'content-type', value: contentNode?.type },
		{ key: 'page-version', value: pageVersion },
		{ key: 'page-title', value: contentNode?.title },
		{
			key: 'parent-page-id',
			value: ptpage?.nearestAncestors?.edges?.[0]?.node?.id,
		},
		{
			key: 'parent-page-title',
			value: ptpage?.nearestAncestors?.edges?.[0]?.node?.title,
		},
		{ key: 'experiment-features', value: experimentFeatures },
	];
	if (contentId) {
		const createPage = pageVersion === 0;
		const pageId = createPage ? '0' : contentId;

		ajsMetaParameters.push({ key: 'content-id', value: contentId });
		ajsMetaParameters.push({ key: 'latest-page-id', value: contentId });
		ajsMetaParameters.push({ key: 'new-page', value: createPage });
		ajsMetaParameters.push({ key: 'page-id', value: pageId });
	}

	const operations = fg('confluence_fe_call_new_legacy_bridge_query')
		? (data as OptimizedLegacyBridgeQueryType)?.contentOperations?.nodes?.[0]?.operations
		: contentNode?.operations;

	if (operations) {
		const canRemovePage =
			operations.filter((item) => item?.operation === 'delete' && item?.targetType === 'page')
				.length > 0;

		ajsMetaParameters.push({ key: 'can-remove-page', value: canRemovePage });
	}

	const filteredAjsMetaParameters = ajsMetaParameters.filter(
		({ value }) => value !== undefined && value !== null,
	);

	useEffect(() => {
		waitForGlobal(
			{
				listener: 'LegacyBridgeNext.js',
				globalProperty: 'AJS.Meta.set',
			},
			() => {
				if (error) {
					logger.error`Failed to Query Legacy AJSMetaData ${error}`;
					return;
				}

				if (
					filteredAjsMetaParameters.length === 0 ||
					window.AJS.Meta.get('has-next-legacy-bridge')
				) {
					return;
				}

				// We have 2 legacy bridge here based on graphql
				// Another in packages/confluence-frontend-server/src/components/LegacyBridge/LegacyBridge.js based on Redux.
				// We need to let the legacy one know we have already dealt with these meta tags don't override
				window.AJS.Meta.set('has-next-legacy-bridge', true);

				filteredAjsMetaParameters.forEach(({ key, value }) => window.AJS.Meta.set(key, value));
			},
		);

		return () => {
			const ajsMeta = window.AJS?.Meta;
			ajsMeta && ajsMeta.set('has-next-legacy-bridge', false);
		};
	}, [error, contentId, filteredAjsMetaParameters]);

	if (renderServerPlaceholder) {
		const sanitizedAJSMetaStr = sanitizeDataForSSR(JSON.stringify(filteredAjsMetaParameters));

		const legacyBridgeSSRScript =
			nonceValue !== undefined
				? `<script nonce=${nonceValue}>window.__SSR_AJS_META__="${sanitizedAJSMetaStr}"</script>`
				: `<script>window.__SSR_AJS_META__="${sanitizedAJSMetaStr}"</script>`;

		return (
			<div
				data-testid="legacyBridge-ssr"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={{ display: 'none' }}
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{
					__html: `${legacyBridgeSSRScript}`,
				}}
			/>
		);
	}

	return null;
}
